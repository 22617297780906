




import { defineComponent, PropType, computed } from '@nuxtjs/composition-api';
import { capitalize } from '~/helpers/utils';
import { useVSFContext } from '@vue-storefront/core';

type Type = 'slider' | 'skyscraper' | 'button';
type LocationType = 'Homepage' | 'PLP_List' | 'PLP_Tiles' | 'PDP';

export default defineComponent({
  name: 'AdSlot',
  props: {
    type: {
      type: String as PropType<Type>,
      required: true
    },
    location: {
      type: String as PropType<LocationType>,
      default: null
    }
  },
  setup(props) {
    const vsfContext = useVSFContext();

    const websiteGroup = computed(() => {
      return (
        vsfContext.$novulo.config.state?.domainConfig?.websiteGroup?.toUpperCase() ||
        ''
      );
    });
    const websiteCountry = computed(() => {
      return websiteGroup.value === 'FG'
        ? ''
        : `_${vsfContext.$novulo.config.state?.domainConfig?.websiteCountry?.toLowerCase()}`;
    });
    const dataAdUnitCode = computed(() => {
      return `22821709290/${websiteGroup.value}${websiteCountry.value}/${
        props.location || ''
      }/${capitalize(props.type)}`;
    });

    return {
      dataAdUnitCode
    };
  }
});
