

















import { PropType, defineComponent } from '@nuxtjs/composition-api';
import useUrlFormatter from '~/hooks/useUrlFormatter';

interface MenuElement {
  id: number;
  code: 'home_header_quick_links';
  urls: [
    {
      code_english: string;
      id: number;
      title: string;
      url: string;
      target: string;
    }
  ];
}

interface DataProp {
  code: string;
  description: string;
  id: number;
  sequence: number;
  elements: MenuElement[];
}

export default defineComponent({
  name: 'MenuQuickLinks',
  props: {
    data: {
      type: Object as PropType<DataProp>,
      default: () => ({})
    }
  },
  setup(props) {
    const formatUrl = useUrlFormatter();

    const menuLinks = props.data?.elements[0]?.urls || [];

    const truncate = (text: string, max = 25) => {
      return text.length > max ? text.slice(0, max - 1) + '…' : text;
    };

    return {
      menuLinks,
      formatUrl,
      truncate
    };
  }
});
